import wait_10 from './wait10_s.json'
import wait_15 from './wait15_s.json'
import wait_20 from './wait20_s.json'
import wait_25 from './wait25_s.json'
import wait_30 from './wait30_s.json'
import wait_35 from './wait35_s.json'
import wait_40 from './wait40_s.json'
import wait_45 from './wait45_s.json'
import wait_50 from './wait50_s.json'
import wait_R_G from './wait red to green.json'
import ArrowUp from './arrow_up.json'
import ArrowRight from './arrow_right.json'
import ArrowLeft from './left_arrow.json'
import RedtoGreen from './wait red to green.json'
import waitPulse from './wait pulse.json'
import ReversePlay from './btn_play_reverse.json'
import timeout from './timeout.json'
import ReverseWait from './wait_reverse.json'
import missed from './missed.json'
import Loader from './loading_icon.json'
import Progress from './progress circle 19.0.json'
import Progress1 from './progress circle 19.0 copy.json'
import howToPlay from './howToPlay.json'
import leftStatic from './leftStatic.json'
import rightStatic from './rightStatic.json'
import upStatic from './upStatic.json'
import leftPressedStatic from './LeftPressedStatic.json'
import rightPressedStatic from './rightPressedStatic.json'
import upPressedStatic from './upPressedStatic.json'
export const AllAnimation = {upPressedStatic,rightPressedStatic,leftPressedStatic,leftStatic,rightStatic,upStatic,howToPlay,Progress,Progress1,Loader,timeout,missed,wait_10,wait_15,wait_20,wait_25,wait_30,wait_35,wait_40,wait_45,wait_50,wait_R_G,ArrowUp,ArrowLeft,ArrowRight,RedtoGreen,waitPulse,ReversePlay,ReverseWait}